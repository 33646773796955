.list {
    margin: 50px 0;
    ul {
        list-style-type: decimal;
        list-style-position: inside;
        padding-left: 20px;
        ul {
            margin-left: 20px;
            ul {
                margin-left: 20px;
                ul {
                    margin-left: 20px;
                }
            }
        }
    }
    li {
        &::marker {
            font-size: 1.25em;
            font-weight: 600;
            margin-right: 1rem;
        }
    }
    >div>ul>li {
        font-size: 1.75rem;
        font-weight: 600;
        margin-bottom: 20px;
        margin-top: 40px;
    }
    ul ul {
        padding-left: 20px;
        list-style-position: outside;
    }
}

.text {
    padding: 40px 0;
    p {
        margin-top: 20px;
        margin-bottom: 32px;
    }
    ul {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 20px;
        li {
            margin: 8px 0;
        }
    }
}

.table {
    tr,
    td {
        border: 1px solid #000;
    }
    td {
        padding: 8px 20px;
    }
}